<template>
  <div class="home-container">
    <div class="index-top" style="position: fixed;">
      <div class="top-title">
        <span>济南</span>
        <van-icon name="location-o" color="#666666" size="0.5rem" />
        <van-search v-model="value" show-action shape="round" placeholder="请输入搜索关键词" >
          <template #action>
            <div style="font-size: 0.33rem;border-radius: 1rem;padding: 0 0.5rem;background-color: #E60027;color: #FFFFFF;"
              >搜索</div>
          </template>
        </van-search>

      </div>
    </div>

    <div style="    background-color: #FFFFFF;margin: 0 auto;padding: 0.5rem;
    padding-top: 1.8rem;font-size:0.37rem
     ;">

      <div style=" color: #666666;
    font-size: 0.4rem;    ">
        热门搜索
      </div>
      <div style="margin-top: 0.35rem;    display: flex;
    flex-wrap: wrap;">

        <span style="    border-radius: 0.2rem;
    background: #F8F8F8;
    box-sizing: border-box;
    padding: 0.2rem;
    margin-top: 0.3rem;
    width: 23%;
    text-align: center;
    margin-right: 0.18rem;" v-for="i in channels">{{i.word}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getSelectBusiSearchHotWordList
  } from '@/api/user'
  import {
    mapState
  } from 'vuex'
  import {
    getItem
  } from '@/utils/storage'

  export default {
    name: 'home',

    props: {},
    data() {
      return {
        active: 0, //被激活的列表
        channels: [], //频道列表
        isChannelEditShow: false //频道编辑层
      }
    },
    computed: {
      ...mapState(['user'])
    },
    watch: {},
    created() {
      this.loadChannels()
    },
    mounted() {},
    methods: {
      async loadChannels() {
       const {
         data
       } = await getSelectBusiSearchHotWordList()
       this.channels = data.data
       console.log(this.channels)
      }

      // onUpdateActive(index) {
      //   this.active = index
      // }
    }
  }
</script>

<style scoped lang="less">
  .my-swipe .van-swipe-item {
    width: 100%;
    height: 3rem;
    // color: #fff;
    // font-size: 20px;
    // line-height: 150px;
    // text-align: center;
    // background-color: #39a9ed;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .van-search__content {
    // background-color: #FFFFFF;
  }

  .van-search--show-action {
    padding: 0;
    margin: 0 0.2rem;
    border-radius: 10rem !important;

  }

  .van-search__action {
    padding: 0;
  }

  .van-search {
    width: 72%;
    background-color: #f7f8fa;
  }


  .home-container {

    .index-top {
      padding: 0.2rem 0;
      background-color: #FFFFFF;
      margin-bottom: 0.5rem;
      width: 100%;
      // height: 2.5rem;
    }

    .top-title {
      display: flex;
      /* align-content: center; */
      align-items: center;
      width: 100%;
      margin: 0 auto;
      padding-top: 0.2rem;
      font-size: 0.35rem;
      justify-content: space-evenly;

      span {
        padding: 0 0.09rem;

      }

    }

    .index-nav {
      font-size: 0.3rem;
      font-weight: 700;

      img {
        height: 0.8rem;
        width: 0.8rem;
      }

      span {
        padding-top: 0.15rem;
        color: #333333;
      }
    }

    .index-banner {

      width: 88%;
      margin: 0 auto;
      background: #fff;
      margin-top: 0.3rem;
      font-size: 0.4rem;
      color: #333333;
      font-weight: 700;
      padding: 0.3rem;
      padding-bottom: 0;
      position: relative;

      div {
        margin-bottom: 0.2rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .list-title {
      background-image: url(../../assets/img/navs.png);
      font-size: 0.4rem;
      width: 100%;
      height: 1.2rem;
      background-size: 100% 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      padding-right: 0.5rem;

      span {
        display: block;
        padding-top: 0.3rem;
        font-weight: 700;
        padding-left: 0.3rem;
      }
    }

    .list-content {
      border-radius: 0.2rem;
      margin-bottom: 0.3rem;
      display: flex;
      background: #fff;
      padding: 0.5rem;

      img {
        display: block;
        width: 1.8rem;
        height: 2rem;
      }
    }

    .list-info {
      padding-left: 0.5rem;

      span {
        display: block;
        font-size: 0.3rem;

        &:nth-child(1) {

          font-size: 0.4rem;
          color: #333333;
          font-family: SourceHanSansCN-Regular;
        }

        &:nth-child(2) {
          padding: 0.03rem 0;
          font-size: 0.3rem;
          background: #FFE9E9;
          border-radius: 18px;
          text-align: center;
          margin: 0.28rem;
          width: 50%;
          color: #E60027;
        }

        &:nth-child(3) {
          font-size: 0.3rem;
          color: #999999;
        }
      }

    }

    .index-bottom {
      /* margin: 0rem 0px; */
      width: 90%;
      background: #fff;
      margin: 0 auto;
      font-size: 0.4rem;
      /* margin-top: 0.1rem; */
      margin-bottom: 2rem;
      padding: 0.5rem;
      text-align: center;
      border-radius: 0.2rem;

    }

    /deep/ .van-nav-bar__title {
      max-width: none;
    }

    .search-btn {
      width: 277px;
      height: 32px;
      background: #5babfb;
      border: none;

      .van-icon {
        font-size: 16px;
      }

      .van-button__text {
        font-size: 14px;
      }
    }





  }
</style>
